import { mq } from "@/constants/breakpoints";
import { blueColor, darkColor, lightBlueColor } from "@/constants/colors";
import useReviewSitesData from "@/hooks/useReviewSitesData";
import ArrowRightIcon from "@/svg/ArrowRightIcon";
import { css } from "@emotion/react";
import { Body } from "../Typography";
import Image from "next/image";

interface ReviewSiteButtonProps {
  type: "g2" | "trustpilot";
}

const ReviewSiteButton = ({ type }: ReviewSiteButtonProps) => {
  const { logo, label, href } = useReviewSitesData(type);
  return (
    <a
      href={href}
      css={css`
        background: #fff;
        width: 343px;
        max-width: 100%;
        border: 2px solid ${blueColor};
        border-radius: 12px;
        transition: background 0.2s ease-in-out;
        cursor: pointer;
        &:hover {
          background: ${blueColor};
        }
        ${mq["lg"]} {
          width: 397px;
        }
      `}
    >
      <div
        css={css`
          padding: 30px 30px 30px 35px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 22px;
          `}
        >
          <Image src={logo} alt={`${type} logo`} height={58} />
          <Body
            css={css`
              font-size: 18px;
              font-weight: 700;
              line-height: 140%;
              color: ${darkColor};
              ${mq["lg"]} {
                font-size: 24px;
              }
            `}
          >
            {label}
          </Body>
        </div>
        <ArrowRightIcon
          css={css`
            color: ${lightBlueColor};
          `}
        />
      </div>
    </a>
  );
};

export default ReviewSiteButton;
